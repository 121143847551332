import React from "react";

import * as styles from './article-item.module.scss'
import Img from 'gatsby-image';
import { Link } from "gatsby"
import { useLocalPath, useSiteQuery, useUrl } from "../../../hooks";

const ArticleItem = ({
    slug,
    heading,
    description,
    articleImage
}) => {
    const { image } = useSiteQuery()
    const baseUrl = useUrl()
    if (articleImage && slug && heading) {
        return (
            <div className={styles.searchListing__itemWrap}>
                <div className={styles.searchListing__item__image}>
                    <Link to={useLocalPath(`/${slug}`)}>
                        <Img
                            fluid={articleImage.fluid}
                            alt={articleImage.title}
                        />
                    </Link>
                </div>
                <div className={styles.searchListing__itemDetails}>
                    <div className={styles.searchListing__itemDetailsWrap}>
                        <div className={styles.searchListing__itemDetailsCopy}>
                            <Link to={useLocalPath(`/${slug}`)}>
                                <p className={styles.searchListing__itemCopyHeadline}>{heading}</p>
                            </Link>
                            <p className={styles.searchListing__itemCopyDesc}>{description}</p>
                        </div>
                    </div>
                </div>
            </div>
        )    
    }else{
        return(
            <></>
        )
    }
}

export default ArticleItem