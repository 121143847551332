import React from "react";

import * as styles from './all-results.module.scss'
import { useSearchQuery } from "../../../hooks/layout/useSearchQuery";
import { useSiteQuery } from "../../../hooks";
import Img from 'gatsby-image';
import { Link } from "gatsby"
import ArticleItem from "../ArticleItem/ArticleItem";
const AllResults = ({
    title,
    homePage,
    collections,
    articles = [],
    products = [],
    results = [],
    setter
}) => {
    
    let {articleButton,recipesButton,productsButton} = useSearchQuery()
    const scrollToTab = () => {
        document?.getElementById('search-tabs')?.scrollIntoView({
            behavior: 'smooth'
        });
    }

    const {showAllProducts} = useSiteQuery();

    return (
        <div>
            <ul className={styles.searchListing__list}>
                <li className={styles.searchListing__resultsItems}>
                    <p className={styles.searchListing__resultsItems__headline}>{articleButton}</p>
                    <ul className={styles.searchListing__result__contentType}>
                        {
                            articles.map((article: any, index: number) => {
                                if (article.slug && article.title && article.thumbnailImage && article.thumbnailImage[0] && index < 7) {
                                    return (
                                        <li className={styles.searchListing__itemArticle} key={index}>
                                            <ArticleItem
                                                slug={article.slug}
                                                heading={article.title}
                                                description={article?.seoDescription?.seoDescription}
                                                articleImage={article.thumbnailImage[0]}
                                            />
                                        </li>
                                    )
                                }
                            })
                        }
                    </ul>
                    {
                        articles.length > 6 &&
                        <div className={styles.searchListing__results__showMore}>
                            <button
                                className={`${styles.searchListing__results__moreButton} ${styles.searchListing__button}`}
                                onClick={() => {
                                    setter('articles')
                                    scrollToTab()
                                }}
                            >show all Articles</button>
                        </div>
                    }
                </li>
                <li className={styles.searchListing__resultsItems}>
                    <p className={styles.searchListing__resultsItems__headline}>{recipesButton}</p>
                    <ul className={styles.searchListing__result__contentType}>
                        {
                            results.map((article: any, index: number) => {
                                if (article.slug && article.title && article.heroImage && index < 7) {
                                    return (
                                        <li className={styles.searchListing__itemArticle} key={index}>
                                            <ArticleItem
                                                slug={article.slug}
                                                heading={article.title}
                                                description={article?.seoDescription?.seoDescription}
                                                articleImage={article.heroImage}
                                            />
                                        </li>
                                    )
                                }
                            })
                        }
                    </ul>
                    {
                        results.length > 6 &&
                        <div className={styles.searchListing__results__showMore}>
                            <button
                                className={`${styles.searchListing__results__moreButton} ${styles.searchListing__button}`}
                                onClick={() => {
                                    setter('recipes')
                                    scrollToTab()
                                }}
                            >show all Recipes</button>
                        </div>
                    }
                    {/* <div className={styles.searchListing__results__showMore}>
                        <button className={`${styles.searchListing__results__moreButton} ${styles.searchListing__button}`}>show all Articles</button>
                    </div> */}
                </li>
                <li className={styles.searchListing__resultsItems}>
                    <p className={styles.searchListing__resultsItems__headline}>{productsButton}</p>
                    <ul className={styles.searchListing__result__contentType}>
                        {
                            products.map((product: any, index: number) => {
                                if (product.slug && product.title && product.thumbnailImage) {
                                    if (product.slug && product.title && product.thumbnailImage && index < 7) {
                                        return (
                                            <li className={styles.searchListing__itemArticle} key={index}>
                                                <ArticleItem
                                                    slug={product.slug}
                                                    heading={product.title}
                                                    description={product.subTitle}
                                                    articleImage={product.thumbnailImage}
                                                />
                                            </li>
                                        )
                                    }
                                }
                            })
                        }
                    </ul>
                    {
                        products.length > 6 &&
                        <div className={styles.searchListing__results__showMore}>
                            { showAllProducts ? <button
                                className={`${styles.searchListing__results__moreButton} ${styles.searchListing__button}`}
                                onClick={() => {
                                    setter('products')
                                    scrollToTab()
                                }}
                            >{showAllProducts}</button> :
                            <button
                                className={`${styles.searchListing__results__moreButton} ${styles.searchListing__button}`}
                                onClick={() => {
                                    setter('products')
                                    scrollToTab()
                                }}
                            >show all Products</button>}
                        </div>
                    }
                </li>
            </ul>
        </div>
    )
}

export default AllResults