// extracted by mini-css-extract-plugin
export var searchListing__button = "all-results-module--search-listing__button--d4559";
export var searchListing__itemArticle = "all-results-module--search-listing__item-article--14015";
export var searchListing__itemCopyDesc = "all-results-module--search-listing__item-copy-desc--8a907";
export var searchListing__itemCopyHeadline = "all-results-module--search-listing__item-copy-headline--9899d";
export var searchListing__itemDetails = "all-results-module--search-listing__item-details--201ac";
export var searchListing__itemDetailsCopy = "all-results-module--search-listing__item-details-copy--1eedc";
export var searchListing__itemDetailsWrap = "all-results-module--search-listing__item-details-wrap--81216";
export var searchListing__itemWrap = "all-results-module--search-listing__item-wrap--414ab";
export var searchListing__item__image = "all-results-module--search-listing__item__image--b57b1";
export var searchListing__list = "all-results-module--search-listing__list--11613";
export var searchListing__result__contentType = "all-results-module--search-listing__result__content-type--1efc1";
export var searchListing__resultsItems = "all-results-module--search-listing__results-items--9debc";
export var searchListing__resultsItems__headline = "all-results-module--search-listing__results-items__headline--63c31";
export var searchListing__results__moreButton = "all-results-module--search-listing__results__more-button--1c1e5";
export var searchListing__results__showMore = "all-results-module--search-listing__results__show-more--02161";