import React, { useState, useEffect } from "react";
import * as styles from './search-component.module.scss';
import { Link } from "gatsby"
import { SearchResults, ArticleSearchResults, RecipeSearchResults } from "../SearchResults/SearchResults";
// import SearchResults from "../SearchResults/SearchResults";
import { useSearchQuery } from "../../../hooks/layout/useSearchQuery";
import AllResults from "../AllResults/AllResults";
import { useStaticQuery, graphql } from "gatsby";
import { useLocale, useLocalPath } from "../../../hooks";
const SearchComponent = () => {
    const {title,searchBar,lookingFor,searchButton,all,articleButton, productsButton,recipesButton,slug,showingheading,searchResults} = useSearchQuery()
    const showing = <span>{showingheading}</span>
    const results = <span>{searchResults}</span>
    const [category, setCategory] = useState("all")
    const locale = useLocale()
    const [text, setText] = useState(false)
    const [resultRecipes, setResultRecipes] = useState([])
    const [resultArticles, setResultArticles] = useState([])
    const [resultProducts, setResultProducts] = useState([])
    const [allRecipes, setAllRecipes] = useState([])
    const [allArticles, setAllArticles] = useState([])
    const [allProducts, setAllProducts] = useState([])
    const [resultRecipesLength, setResultRecipesLength] = useState(0)
    const [resultArticlesLength, setResultArticlesLength] = useState(0)
    const [resultProductsLength, setResultProductsLength] = useState(0)

    const {
        allContentfulPageProducts: {
            nodes: products
        },
        allContentfulPageArticle: {
            nodes: articles
        },
        allContentfulPageRecipe: {
            nodes: recipes
        }
    } = useStaticQuery(
        graphql`
            query Search {
                allContentfulPageProducts(filter: {slug: {ne: null}, title: {ne: null}}) {
                    nodes {
                        node_locale
                        slug
                        title
                        subTitle
                        thumbnailImage{
                            title
                            fluid {
                                ...GatsbyContentfulFluid_withWebp_noBase64
                            }
                        }
                        filters {
                            filterTitle
                        }
                    }
                }
                allContentfulPageArticle(filter: {slug: {ne: null}, title: {ne: null}}) {
                    nodes {
                        node_locale
                        title
                        slug
                        seoDescription {
                            seoDescription
                        }
                        thumbnailImage{
                            title
                            fluid {
                                ...GatsbyContentfulFluid_withWebp_noBase64
                            }
                        }
                    }
                }
                allContentfulPageRecipe(filter: {slug: {ne: null}, title: {ne: null}}) {
                    nodes {
                        node_locale
                        seoDescription {
                            seoDescription
                        }
                        title
                        slug
                        heroImage{
                            title
                            fluid {
                                ...GatsbyContentfulFluid_withWebp_noBase64
                            }
                        }
                    }
                }
            }
          
        `
    )
    useEffect(() => {
        const value = products.filter((object) => object.node_locale === locale)
        setAllProducts(value)
        const articleValue = articles.filter((object) => object.node_locale === locale)
        setAllArticles(articleValue)
        const recipeValue = recipes.filter((object) => object.node_locale === locale)
        setAllRecipes(recipeValue)
    }, [])
    const findValue = (e) => {
        const results = allProducts.filter((object) =>
            object?.title?.toLowerCase().includes(e.target.value.toLowerCase())
            || object?.subTitle?.toLowerCase().includes(e.target.value.toLowerCase())
            || object?.filters?.filterTitle?.toLowerCase().includes(e.target.value.toLowerCase())
        )
        setResultProducts(results)
        setResultProductsLength(results.length)
        const articleResults = allArticles.filter((object) =>
            object?.title?.toLowerCase().includes(e.target.value.toLowerCase())
            || object?.seoDescription?.seoDescription.toLowerCase().includes(e.target.value.toLowerCase())
        )
        setResultArticles(articleResults)
        setResultArticlesLength(articleResults.length)

        const recipeResults = allRecipes.filter((object) =>
            object?.title?.toLowerCase().includes(e.target.value.toLowerCase())
            || object?.seoDescription?.seoDescription.toLowerCase().includes(e.target.value.toLowerCase())
        )

        setResultRecipes(recipeResults)
        setResultRecipesLength(recipeResults.length)

    }

    return (
        <div>
            <div className={styles.searchTop}>
                <div className={`styles.search`}>
                    {
                        !text &&
                        <div className={styles.searchHeader}>
                            <h1 className={styles.headerTitle}>{title}</h1>
                        </div>
                    }
                    <div className={styles.wrapper}>
                        {/* <div className={styles.results}> */}
                        {
                            (locale!=="tr-TR" && text) &&
                            <div className={styles.resultsSearchListing}>
                                <span>{showing} </span>
                                <span>{resultProductsLength + resultArticlesLength + resultRecipesLength}</span>
                                <span> {results}</span>
                            </div>
                        }
                        {
                            (locale==="tr-TR" && text) && 
                            <div className={styles.resultsSearchListing}>
                                <span>{resultProductsLength + resultArticlesLength + resultRecipesLength}</span>
                                <span> {results} </span>
                                <span>{showing}</span>
                            </div>
                        }
                        <div className={styles.searchBar}>
                        <label htmlFor="search-input" className={styles.visuallyHidden}>Search</label>
                            <input
                                id="search-input"
                                className={styles.searchInput}
                                type="text"
                                placeholder={searchBar}
                                autoCapitalize="false"
                                autoCorrect="false"
                                spellCheck="false"
                                onChange={(e) => {
                                    setText(true)
                                    findValue(e)
                                }}
                            />
                        </div> 
                        {
                            text &&
                            <>
                                <div className={styles.tabWrapper}>
                                    <div className={`styles.tabs`} id="search-tabs">
                                        <ul className={styles.tabs__list}>
                                            <li
                                                className={styles.tabs__listItem}
                                                onClick={() => {
                                                    setCategory("all")
                                                }}
                                            >
                                                <button className={`${styles.tabs__listItemButton} ${category === 'all' && styles.tabs__listItemButtonActive}`}>{all}(<span>{resultProductsLength + resultArticlesLength + resultRecipesLength}</span>)</button>
                                            </li>
                                            <li
                                                className={styles.tabs__listItem}
                                                onClick={() => {
                                                    setCategory("articles")
                                                }}
                                            >
                                                <button className={`${styles.tabs__listItemButton} ${category === 'articles' && styles.tabs__listItemButtonActive}`}>{articleButton}(<span>{resultArticlesLength}</span>)</button>
                                            </li>
                                            {/* <li
                                                className={styles.tabs__listItem}
                                                onClick={() => {
                                                    setCategory("home-page")
                                                }}
                                            >
                                                <button className={`${styles.tabs__listItemButton} ${category === 'home-page' && styles.tabs__listItemButtonActive}`}>Home page(<span>61</span>)</button>
                                            </li>
                                            
                                            <li
                                                className={styles.tabs__listItem}
                                                onClick={() => {
                                                    setCategory("collections")
                                                }}
                                            >
                                                <button className={`${styles.tabs__listItemButton} ${category === 'collections' && styles.tabs__listItemButtonActive}`}>Collections(<span>61</span>)</button>
                                            </li> */}
                                            <li
                                                className={styles.tabs__listItem}
                                                onClick={() => {
                                                    setCategory("products")
                                                }}
                                            >
                                                <button className={`${styles.tabs__listItemButton} ${category === 'products' && styles.tabs__listItemButtonActive}`}>{productsButton}(<span>{resultProductsLength}</span>)</button>
                                            </li>
                                           { locale !== "sv-SE" ?
                                            <li
                                                className={styles.tabs__listItem}
                                                onClick={() => {
                                                    setCategory("recipes")
                                                }}
                                            >
                                                <button className={`${styles.tabs__listItemButton} ${category === 'recipes' && styles.tabs__listItemButtonActive}`}>{recipesButton}(<span>{resultRecipesLength}</span>)</button>
                                            </li> : ""}
                                            {/* <li
                                                className={styles.tabs__listItem}
                                                onClick={() => {
                                                    setCategory("social-article")
                                                }}
                                            >
                                                <button className={`${styles.tabs__listItemButton} ${category === 'social-article' && styles.tabs__listItemButtonActive}`}>Social Article(<span>61</span>)</button>
                                            </li>
                                            <li
                                                className={styles.tabs__listItem}
                                                onClick={() => {
                                                    setCategory("sublanding-page")
                                                }}
                                            >
                                                <button className={`${styles.tabs__listItemButton} ${category === 'sublanding-page' && styles.tabs__listItemButtonActive}`}>Sublanding page(<span>61</span>)</button>
                                            </li>  */}
                                        </ul>
                                    </div>
                                </div>
                                <div className={styles.contentWrapper}>
                                    {
                                        category === 'all' &&
                                        <AllResults
                                            setter={setCategory}
                                            articles={resultArticles}
                                            products={resultProducts}
                                            results={resultRecipes}
                                        />
                                    }
                                    {
                                        category === 'articles' &&
                                        <ArticleSearchResults
                                            title={articleButton}
                                            results={resultArticles}
                                        />
                                    }
                                    {/* {
                                        category === 'home-page' &&
                                        <SearchResults title="Home page" />
                                    }
                                    {
                                        category === 'collections' &&
                                        <SearchResults title="Collections" />
                                    } */}
                                    {
                                        category === 'products' &&
                                        <SearchResults
                                            title={productsButton}
                                            results={resultProducts}
                                        />
                                    }
                                    {
                                        category === 'recipes' &&
                                        <RecipeSearchResults
                                            title={recipesButton}
                                            results={resultRecipes}
                                        />
                                    }
                                    {/* {
                                        category === 'social-article' &&
                                        <SearchResults title="Social Article" />
                                    }
                                    {
                                        category === 'sublanding-page' &&
                                        <SearchResults title="Sublanding page" />
                                    } */}
                                </div>
                            </>
                        }
                    </div>
                </div>
                <div className={styles.contactUs}>
                    <div className={styles.cantFind}>
                        <div className={styles.container}>
                            <p>{lookingFor}</p>
                        </div>
                    </div>
                    <Link to={useLocalPath(`/${slug}`)} >
                    <button className={styles.cantFindButton}>{searchButton}</button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default SearchComponent;