import React, { useState } from "react";

import * as styles from './search-results.module.scss'
import ArticleItem from "../ArticleItem/ArticleItem";
import { useSiteQuery } from "../../../hooks";

export const SearchResults = ({
    title,
    results = []
}) => {
    const [display, setDisplay] = useState(false);

    const {showAllProducts} = useSiteQuery();
    return (
        <div>
            <div>{title}</div>
            <ul className={styles.searchListing__list}>
                {
                    results.map((product: any, index: number) => {
                        if (product.slug && product.title && product.thumbnailImage) {
                            if (product.slug && product.title && product.thumbnailImage) {
                                return display ? (
                                    <li className={styles.searchListing__item} key={index}>
                                        <ArticleItem
                                            slug={product.slug}
                                            heading={product.title}
                                            description={product.subTitle}
                                            articleImage={product.thumbnailImage}
                                        />
                                    </li>
                                ) : (index < 7 &&
                                    <li className={styles.searchListing__item} key={index}>
                                        <ArticleItem
                                            slug={product.slug}
                                            heading={product.title}
                                            description={product.subTitle}
                                            articleImage={product.thumbnailImage}
                                        />
                                    </li>
                                )
                            }
                        }
                    })
                }
            </ul>
            {
                results.length > 6 && !display &&
                <div className={styles.searchListing__results__showMore}>
                    {showAllProducts ? <button
                        className={`${styles.searchListing__results__moreButton} ${styles.searchListing__button}`}
                        onClick={() => setDisplay(true)}
                    >{showAllProducts}</button> : 
                    <button
                        className={`${styles.searchListing__results__moreButton} ${styles.searchListing__button}`}
                        onClick={() => setDisplay(true)}
                    >Show All Products</button>}
                </div>
            }
        </div>
    )
}

export const ArticleSearchResults = ({
    title,
    results = []
}) => {

    const [display, setDisplay] = useState(false)
    return (
        <div>
            <div>{title}</div>
            <ul className={styles.searchListing__list}>
                {
                    results.map((article: any, index: number) => {
                        if (article.slug && article.title && article.thumbnailImage) {
                            return display ? (
                                <li className={styles.searchListing__item} key={index}>
                                    <ArticleItem
                                        slug={article.slug}
                                        heading={article.title}
                                        description={article?.seoDescription?.seoDescription}
                                        articleImage={article.thumbnailImage[0]}
                                    />
                                </li>
                            ) : (index < 7 &&
                                <li className={styles.searchListing__item} key={index}>
                                    <ArticleItem
                                        slug={article.slug}
                                        heading={article.title}
                                        description={article?.seoDescription?.seoDescription}
                                        articleImage={article.thumbnailImage[0]}
                                    />
                                </li>
                            )
                        }
                    })
                }
            </ul>
            {
                results.length > 6 && !display &&
                <div className={styles.searchListing__results__showMore}>
                    <button
                        className={`${styles.searchListing__results__moreButton} ${styles.searchListing__button}`}
                        onClick={() => setDisplay(true)}
                    >Show all</button>
                </div>
            }
        </div>
    )
}


export const RecipeSearchResults = ({
    title,
    results = []
}) => {
    const [display, setDisplay] = useState(false)
    return (
        <div>
            <div>{title}</div>
            <ul className={styles.searchListing__list}>
                {
                    results.map((article: any, index: number) => {
                        if (article.slug && article.title && article.heroImage) {
                            return display ? (
                                <li className={styles.searchListing__item} key={index}>
                                    <ArticleItem
                                        slug={article.slug}
                                        heading={article.title}
                                        description={article?.seoDescription?.seoDescription}
                                        articleImage={article.heroImage}
                                    />
                                </li>
                            ) : (index < 7 &&
                                <li className={styles.searchListing__item} key={index}>
                                    <ArticleItem
                                        slug={article.slug}
                                        heading={article.title}
                                        description={article?.seoDescription?.seoDescription}
                                        articleImage={article.heroImage}
                                    />
                                </li>
                            )
                        }
                    })
                }
            </ul>
            {
                results.length > 6 && !display &&
                <div className={styles.searchListing__results__showMore}>
                    <button
                        className={`${styles.searchListing__results__moreButton} ${styles.searchListing__button}`}
                        onClick={() => setDisplay(true)}
                    >show all Products</button>
                </div>
            }
        </div>
    )
}


// export default SearchResults