import React from 'react'
import SearchComponent from '../components/SearchComponent/SearchComponent/SearchComponent'
import Seo from '../components/seoGlobal'
import { useLocale } from '../hooks'
import Schema from '../components/schema'
import { graphql } from 'gatsby'
import { getPathname } from "../utils/functions"

const PageSearch = ({
    location,
    pageContext,
    data:{
        contentfulPageCustom:{
            seoDescription,
            seoTitle,
            slug
        },
        allContentfulPageCustom: {
            nodes: allLinks
        }
    }
})=>{
    const locale = useLocale()

    return(
        <>
            <Seo 
                lang={locale}
                title={seoTitle}
                description={seoDescription?.seoDescription}
                hrefLangs={allLinks}
            />
            <Schema slug={getPathname(pageContext.node_locale,slug)}/>
            <SearchComponent/>
        </>
    )
}
export const PageSearchQuery = graphql`
    query pageSearch($id: String!, $contentful_id: String!){
        contentfulPageCustom(id: { eq: $id }) {
            seoTitle
            seoDescription{
                seoDescription
            }
        }
        allContentfulPageCustom(filter: {contentful_id: {eq: $contentful_id}, id: {ne: $id}, slug: {ne: null}}){
            nodes {
                slug
                node_locale
            }
        }
    }
`
export default PageSearch